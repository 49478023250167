
















































import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

export interface IHandoverPeriod {
  periodFrom: string;
  periodTo: string;
}

export default defineComponent({
  name: "SetPeriodModal",
  components: {
    DatePicker,
  },
  props: {
    cbOnSetPeriod: {
      type: Function,
      default: () => 1,
    },
    changedPeriod: {
      type: Object,
      default: () => ({ periodFrom: "", periodTo: "" }),
    },
  },
  computed: {
    periodFrom: {
      get(): string {
        return this.changedPeriod.periodFrom as string;
      },
      set(value: string): void {
        if (this.changedPeriod.periodTo) {
          this.changedPeriod.periodTo = "";
        }
        this.changedPeriod.periodFrom = value;
      },
    },
    currentYear() {
      return new Date().getFullYear().toString();
    },
  },
  model: {
    prop: "changedPeriod",
    event: "change",
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    setPeriodCb() {
      (this as { cbOnSetPeriod: (changedPeriod: IHandoverPeriod) => void }).cbOnSetPeriod?.(this.changedPeriod as IHandoverPeriod);
      this.closeModal();
    },
  },
});
